@import "~bootstrap/scss/functions";
@import "bs_variables";
@import "~bootstrap/scss/bootstrap";
@import '~bootstrap-icons/font/bootstrap-icons';

@import "home";
@import "about";
@import "cv";

html[data-bs-theme="dark"] {
    img,
    canvas {
        filter: brightness(.8);
    }
}

::selection {
    color: $gray-200;
    background: $primary;
}

#languageDropdownMenuLink {
    &:before {
        display: none;
    }
}

a {
    text-decoration: none;
}

#about {
    a {
        &:hover {
            background-color: $primary;
            color: #fff;
        }
    }
}

.hand {
    cursor: pointer;
}

.bg-dark-trans {
    background-color: rgba(#000000, .9)
}

.form-floating {
    &:has(#middle-name) {
        display: none;
    }
}

.hand {
    cursor: pointer;
}
