@keyframes mover {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(10px);
    }
}

#header {
    // height: calc(100vh - 112px); // leave room for navbar and bottom nav in this section
    // height: calc(100vh - 56px); // compensate for top navbar
    height: 95vh;

    .home-box {
        a {
            color: #afafaf;
        }

        img {
            border-radius: 50%;
            border: 2px solid #ccc;
            max-width: 140px;
            filter: brightness(.8);
        }

        @media (min-width: 992px) {
            img {
                max-width: 250px;
            }
        }
    }

    .scroll-btn {
        bottom: 65px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        border: 1px solid #fff;
        padding: 10px 10px 20px;
        border-radius: 30px;

        .scroll-bar {
            animation: mover 1s infinite alternate;
            span {
                border: 2px solid #fff;
                font-size: 10px;
                border-radius: 30px;
            }
        }
    }
}

@media (min-width: 992px) {
    #header {
        height: 85vh;
    }
}
