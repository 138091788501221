.timeline {
    position: relative;
    list-style-type: none;

    li {
        margin-left: 2rem;
        margin-bottom: 1.5rem;

        &:before {
            content: '';
            background-color: #fff;
            display: inline-block;
            position: absolute;
            border-radius: 50%;
            border: 2px solid #000;
            left: 1.37rem;
            width: 1.5rem;
            height: 1.5rem;
            z-index: 1;
            margin-top: .5rem;
        }
    }

    &:before {
        content: '';
        background: #555;
        display: inline-block;
        position: absolute;
        left: 2rem;
        width: 3px;
        height: 100%;
        z-index: 1;
    }
}
